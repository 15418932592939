@font-face {
  font-family: "Avenir-roman";
  src: local("Avenir"),
    url("./assets/fonts/avenir/AvenirLTStd-Roman.otf") format("truetype");
}
@font-face {
  font-family: "Avenir-book";
  src: local("Avenir"),
    url("./assets/fonts/avenir/AvenirLTStd-Book.otf") format("truetype");
}
@font-face {
  font-family: "Avenir-medium";
  src: local("Avenir"),
    url("./assets/fonts/avenir/AvenirLTStd-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Roboto-mono";
  src: url("./assets/fonts/Roboto_Mono/RobotoMono-VariableFont_wght.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, monospace;
}
