/* Split the screen in half */
body {
  background-color: #e5e5e5;
}

* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
}

/* Control the left side */
.left {
  height: auto;
  width: 60%;
  display: flex;
  padding: 0 24px 0 24px;
  box-sizing: border-box;
}

.mainContainer {
  display: flex;
  margin-top: 12px;
  box-sizing: border-box;
  position: relative;
  align-items: stretch;
}

.graphContainer {
  width: 40%;
  box-sizing: border-box;
  padding: 12px 12px;
}

/* Control the right side */
.splitright {
  height: auto;
  width: 100%;
}

.decision {
  white-space: nowrap;
}

.output {
  font-family: monospace;
}

.mono {
  font-family: monospace;
}

.emptydiv {
  height: 96px;
}

.variable {
  float: right;
}

.policy {
  position: relative;
  bottom: -6px;
}

.examples > a,
.examples > span {
  padding-right: 24px;
}

span {
  display: inline-block;
}

canvas.marks {
  /* these "important"s don't bother me as much as the other ones*/
  /* (it's just for styling charts and hasn't been a big hassle)
  /*this makes the altair images responsive*/
  width: 100% !important;
  height: auto !important;
}

/* Customize the label (the container) */
.container {
  display: initial;
  align-items: center;
  position: relative;
  padding-left: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 12px;
}

@media only screen and (max-width: 900px) {
  .left {
    width: 100%;
  }
  .graphContainer {
    width: 100%;
  }
  .mainContainer {
    flex-direction: column;
  }
}

textarea {
  resize: none;
}

.errorMessage {
  color: red;
  padding-left: 36px;
}
